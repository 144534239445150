import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { TermsDialogComponent } from '../../shared/terms-dialog/terms-dialog.component';
import { AuthService } from '../../services/auth.service';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';

import { ChangeOrganisationDialogComponent } from '../../shared/change-organisation-dialog/change-organisation-dialog.component';



export interface NavItem {
  text: string;
  icon: IconName;
  url?: string;
  permissions?: string[];
  superadmins?: boolean;
  customItem?: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  helpLinkUrl: string;
  HELP_CA_LINK_ARRAY = [
    {
      lang: 'fi',
      url: 'https://help.kiwasupport.com/competenceadmin',
    },
    {
      lang: 'en',
      url: 'https://help.kiwasupport.com/en/competenceadmin',
    },
    {
      lang: 'se',
      url: 'https://help.kiwasupport.com/sv/competenceadmin',
    },
  ];
  navItems: NavItem[] = [
    {
      text: 'sidebar.home',
      url: '/home',
      icon: 'home-lg-alt',
    },
    {
      text: 'sidebar.trainings',
      url: '/trainings',
      icon: 'graduation-cap',
      permissions: ['view_own_trainings', 'view_all_trainings_in_system'],
    },
    {
      text: 'sidebar.events',
      url: '/events',
      icon: 'star',
      permissions: ['view_events', 'view_own_events', 'view_all_events_in_system'],
    },
    {
      text: 'sidebar.locations',
      url: '/locations',
      icon: 'map-marker-alt',
      permissions: ['view_own_locations', 'view_all_locations_in_system'],
    },
    {
      text: 'sidebar.training_codes',
      url: '/trainingcodes',
      icon: 'chalkboard-teacher',
      permissions: ['view_training_codes', 'view_all_training_codes_in_system'],
    },
    {
      text: 'sidebar.training_templates',
      url: '/trainingtemplates',
      icon: 'table',
      permissions: ['view_training_templates', 'view_all_training_templates_in_system'],
    },
    {
      text: 'sidebar.organisations',
      url: '/organisations',
      icon: 'building',
      permissions: ['view_own_organisations', 'view_all_organisations_in_system'],
    },
    {
      text: 'sidebar.statistics',
      url: '/statistics',
      icon: 'stream',
      permissions: ['view_trainings', 'view_all_trainings_in_system'],
    },
    {
      text: 'sidebar.users',
      url: '/users',
      icon: 'users',
      permissions: ['view_users', 'view_all_users_in_system'],
    },
    {
      text: 'sidebar.materials',
      url: '/materials',
      icon: 'file',
      customItem: true,
    },
    {
      text: 'sidebar.cards',
      url: '/cards',
      icon: 'id-card',
      superadmins: true,
    },
    {
      text: 'sidebar.concept',
      url: '/concepts',
      icon: 'book',
      superadmins: true,
    },
    {
      text: 'sidebar.companies',
      url: '/companies',
      icon: 'sitemap',
      superadmins: true,
    },
    {
      text: 'sidebar.help',
      icon: 'question',
      customItem: true,
    },
    {
      text: 'sidebar.admin',
      url: '/superadmins',
      icon: 'cog',
      superadmins: true,
    },
  ];

  @Output()
  emitSidebarStatus = new EventEmitter();

  selectingLanguage = false;
  menuOpen = true;
  logoutSubscription: Subscription;
  userId: number;
  user;
  jwtHelper = new JwtHelperService();

  constructor(
    public router: Router,
    public globals: Globals,
    public auth: AuthService,
    private http: HttpClient,
    public authGuardService: AuthGuardService,
    private userService: UserService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private termsDialogComponent: TermsDialogComponent,
    private changeOrganisationDialog: ChangeOrganisationDialogComponent,

  ) {}

  get visibleNavItems(): NavItem[] {
    return this.navItems.filter(item =>
      item.superadmins ? this.authGuardService.isSuperAdmin() : (!item.permissions || this.authGuardService.isAllowed(item.permissions)),
    );
  }

  get organisationId(): number {
    return this.authGuardService.getSessionData() && this.authGuardService.getSessionData().user.logged_in_organisation
      ? this.authGuardService.getSessionData().user.logged_in_organisation.id
      : null;
  }

  ngOnInit() {
    const decodedToken = this.jwtHelper.decodeToken(this.auth.getToken());
    if (decodedToken) {
      this.userId = decodedToken.sub;
      // This part of the app gets run when user is logged in application initialises so let's ask here if user has terms to accept
      this.userService.getCurrentUserDetails().subscribe((res: any) => {
        // update session
        const sessionData = {
          user: res.data.user,
          permissions: res.data.permissions,
          superadmin: res.data.superadmin,
          concept_id: res.data.concept_id, 
        };
        if (res.data.show_materials) {
          sessionData['show_materials'] = true;
        }
        this.authGuardService.setSessionData(sessionData);

        if (res.data.accept_terms.length > 0) {
          this.termsDialogComponent.confirm({
            accept_terms: res.data.accept_terms,
            accept: () => {
              this.user = res.data.user;
            },
            // If user did not accept all the terms we log out
            reject: () => {
              this.logout();
            },
          });
        } else {
          this.user = res.data.user;
        }
      });
    }
    if (window.innerWidth <= 768) {
      this.menuOpen = false;
    }

    this.setHelpLinkUrl(this.translate.currentLang);
  }

  showMaterials() {
    // Check if session data contains show_materials. If it is does not we can delete materials from sidebar.
    return (
      (this.authGuardService.getSessionData() && this.authGuardService.getSessionData().show_materials) ||
      this.authGuardService.isSuperAdmin()
    );
  }

  getOrganisationLogo() {
    let logo =
      this.authGuardService.getSessionData() &&
      this.authGuardService.getSessionData().user &&
      this.authGuardService.getSessionData().user.logged_in_organisation &&
      this.authGuardService.getSessionData().user.logged_in_organisation.logo
        ? this.authGuardService.getSessionData().user.logged_in_organisation.logo.url
        : null;
    if (!logo) {
      logo = '../../../assets/ca_logo.svg';
    }
    return logo;
  }

  setHelpLinkUrl(language: string) {
    this.helpLinkUrl = this.HELP_CA_LINK_ARRAY.find(element => element.lang === language).url;
  }

  changeLanguage(language) {
    this.userService
      .updateUser(this.userService.getCurrentUserId(), {
        firstname: this.authGuardService.getSessionData().user.firstname,
        lastname: this.authGuardService.getSessionData().user.lastname,
        email: this.authGuardService.getSessionData().user.email,
        language_id: this.globals.languages.find(lang => lang.code === language).id,
      })
      .subscribe(res => {
        this.languageService.changeLanguage(language);
        this.selectingLanguage = false;
        this.setHelpLinkUrl(language);
      });
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.emitSidebarStatus.emit(this.menuOpen);
  }

  logout() {
      this.auth.logout().subscribe(() => {
        this.authGuardService.clearSessionData();
        this.router.navigate(['/login']);
      });
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  openInNewTab(url: string): void {
    const newTab = window.open(url, '_blank');
    newTab.opener = null;
  }

  changeOrganisation() {
    this.changeOrganisationDialog.confirm({
      title: 'users.change_organisation_modal_title',
      text: 'users.change_organisation_modal_text',
      buttonText: 'common.save',
      accept: () => {

      },
    });  
  }

  
}

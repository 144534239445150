import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { CurrentUserDetails, User, UserParams } from '../models/user.model';
import { Pagination } from '../models/pagination.model';
import { AuthService } from './auth.service';
import {Observable} from "rxjs";

export interface UserResponse {
  status: string;
  success: boolean;
  data: any;
  pagination?: Pagination;
}

export interface CreateUserResponse {
  status: string;
  success: boolean;
  data: User;
}

export interface CurrentUserResponse {
  status: string;
  success: boolean;
  data: CurrentUserDetails;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  jwtHelper = new JwtHelperService();

  url = `${environment.apiUrl}v1/users`;

  constructor(private http: HttpClient, private auth: AuthService) {}

  enable2FA(): Observable<any> {
    return this.http.post(environment.apiUrl + 'v1/auth/2fa/enable', {});
  }

  confirm2FA(code): Observable<any> {
    return this.http.post(environment.apiUrl + 'v1/auth/2fa/confirm', {code});
  }

  disable2FA(): Observable<any> {
    return this.http.delete(environment.apiUrl + 'v1/auth/2fa/disable');
  }

  regenerate2FACodes(): Observable<any> {
    return this.http.post(environment.apiUrl + 'v1/auth/2fa/regenerate-codes', {});
  }

  getCurrentUserId() {
    const decodedToken = this.jwtHelper.decodeToken(this.auth.getToken());
    return decodedToken ? parseInt(decodedToken.sub, 10) || null : null;
  }

  sendPasswordResetLink(email) {
    return this.http.post(environment.apiUrl + 'v1/resetemail', { email }, { headers: { code: 'ca' } });
  }

  addUser(params?: UserParams) {
    return this.http.post<CreateUserResponse>(environment.apiUrl + 'v1/users', params);
  }

  getAll(params: {} = {}) {
    return this.http.get<UserResponse>(environment.apiUrl + 'v1/users', params);
  }

  updateUser(userId, params: any) {
    return this.http.put<UserResponse>(environment.apiUrl + 'v1/users/' + userId, params);
  }

  getCurrentUserDetails() {
    return this.http.get<CurrentUserResponse>(environment.apiUrl + 'v1/users/me');
  }

  updateUserPassword(userId, params: any) {
    return this.http.put<UserResponse>(`${environment.apiUrl}v1/users/${userId}/password`, params);
  }

  patchUser(userId, params: any) {
    return this.http.patch<UserResponse>(environment.apiUrl + 'v1/users/' + userId, params);
  }

  checkUser(userEmail: string) {
    return this.http.post<UserResponse>(environment.apiUrl + 'v1/checkuser', { email: userEmail });
  }

  addRole(userId, params: any) {
    return this.http.post<UserResponse>(environment.apiUrl + 'v1/users/' + userId + '/roles', params);
  }

  getUser(userId, params?: any) {
    return this.http.get<UserResponse>(environment.apiUrl + 'v1/users/' + userId, params);
  }

  deactivateUser(userId) {
    return this.http.delete(environment.apiUrl + 'v1/users/' + userId);
  }

  reactivateUser(userId) {
    return this.http.patch(environment.apiUrl + 'v1/users/' + userId, { status: 'active' });
  }

  getAvailableRoles() {
    return this.http.get<UserResponse>(`${environment.apiUrl}v1/roles`);
  }

  mergeUsers(userToKeepId: number, userToMergeId: number) {
    return this.http.post(environment.apiUrl + 'v1/users/' + userToKeepId + '/merge/' + userToMergeId, { merge_as_user_id: userToKeepId });
  }
  getUserConcepts(userId: number) {
    return this.http.get(environment.apiUrl + 'v1/users/'+ userId +'/concepts');
  }
}
